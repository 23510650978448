import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form } from "react-bootstrap";
import { EnvelopeAtFill } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import BannerUploaderEdit from "../../components/bannerUploaderEdit/bannerUploaderEdit";
import CustomLoader from "../../components/loader/loader";
import ProfileUploader from "../../components/profileUploader/profileUploader";
import TokenDetails from "../../components/useUserDetails/useTokenDetails";
import * as Actions from "../../constants/baseUrl";
import imgPlacehold from "../../img/user.png";

const ProfileEdit = () => {
  const entities = "users";

  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const [loading, setLoading] = useState(true);
  const [reqLoading, setReqLoading] = useState(false);

  const [userData, setUserData] = useState({});
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    whatsapp: "",
    website: "",
    job_title: "",
    state: "",
    city: "",
    country: "",
    photo_image_content: "",
    photo_image_extension: "",
    photo_image_url: "",
    hero_image_content: "",
    hero_image_extension: "",
    hero_image_url: "",
  });

  const handleFieldChange = (fieldName, fieldValue) => {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));
  };

  const populateList = async () => {
    try {
      const data = await fetch(
        `${Actions.BASE_URL}${entities}/email/${myUserId}`
      ).then((response) => {
        console.log(response);
        return response.json();
      });

      setFormData({
        ...formData,
        photo_image_url: data?.photo_image_url,
        first_name: data?.first_name,
        last_name: data?.last_name,
        email: data?.email,
        phone: data?.phone,
        whatsapp: data?.whatsapp,
        website: data?.website,
        job_title: data?.job_title,
        state: data?.state,
        city: data?.city,
        country: data?.country,
      });
      setUserData(data);
      setLoading(false);
    } catch (error) {
      console.log("There was an error", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    populateList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setReqLoading(true);
    axios
      .put(`${Actions.BASE_URL}${entities}/${userData?.id}`, formData)
      .then((res) => {
        window.location = `/my-profile`;
        setReqLoading(false);
      });
  };

  return (
    <>
      {reqLoading ? (
        <>
          <span className="register-loader"> </span>
          <span className="spinnerOverlay"></span>
        </>
      ) : null}

      {loading ? (
        <CustomLoader />
      ) : (
        <Container fluid="lg" className="py-5 mt-5 profile-edit">
          <h3 className="heading-text theme-text-2 text-center pt-3">
            Edit Profile
          </h3>
          <div className="demosAddForm">
            <Form className="row" onSubmit={handleSubmit}>
              <Col md={12} className="images-upload position-relative">
                <BannerUploaderEdit
                  label="Cover Photo"
                  accept="image/png, image/jpg, image/jpeg"
                  imageType="hero_image"
                  placehold="https://placehold.co/1024x200"
                  state={formData}
                  setState={setFormData}
                  imageURL={formData.hero_image_url}
                  imageContent={formData.hero_image_content}
                  imageExtension={formData.hero_image_extension}
                />
                <ProfileUploader
                  label="Profile"
                  accept="image/png, image/jpg, image/jpeg"
                  imageType="photo_image"
                  placehold={imgPlacehold}
                  state={formData}
                  resolution={280}
                  setState={setFormData}
                  imageURL={formData?.photo_image_url}
                  imageContent={formData?.photo_image_content}
                  imageExtension={formData?.photo_image_extension}
                />
              </Col>
              <Col md={6}>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    type="text"
                    id="first_name"
                    name="first_name"
                    placeholder="Type"
                    value={formData?.first_name}
                    onChange={(e) =>
                      handleFieldChange("first_name", e.target.value)
                    }
                  />
                  <label htmlFor="first_name">First Name</label>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    type="text"
                    id="last_name"
                    name="last_name"
                    placeholder="Type"
                    value={formData?.last_name}
                    onChange={(e) =>
                      handleFieldChange("last_name", e.target.value)
                    }
                  />
                  <label htmlFor="last_name">Last Name</label>
                </Form.Group>
              </Col>

              <Col md={12}>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    type="text"
                    id="job_title"
                    name="job_title"
                    placeholder="job_title"
                    value={formData?.job_title}
                    onChange={(e) =>
                      handleFieldChange("job_title", e.target.value)
                    }
                  />
                  <label htmlFor="job_title">Job Title</label>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    disabled
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    value={formData?.email}
                    // onChange={(e) => handleFieldChange("email", e.target.value)}
                  />
                  <label htmlFor="email">Email</label>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    type="tel"
                    id="phone"
                    name="phone"
                    placeholder="Phone"
                    value={formData?.phone}
                    onChange={(e) => handleFieldChange("phone", e.target.value)}
                  />
                  <label htmlFor="phone">Phone</label>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    type="tel"
                    id="whatsapp"
                    name="whatsapp"
                    placeholder="WhatsApp"
                    value={formData?.whatsapp}
                    onChange={(e) =>
                      handleFieldChange("whatsapp", e.target.value)
                    }
                  />
                  <label htmlFor="whatsapp">WhatsApp</label>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    type="url"
                    id="website"
                    name="website"
                    placeholder="Website"
                    value={formData?.website}
                    onChange={(e) =>
                      handleFieldChange("website", e.target.value)
                    }
                  />
                  <label htmlFor="website">Website</label>
                </Form.Group>
              </Col>

              <Col md={6} className="d-flex flex-md-row flex-column gap-3">
                <Form.Group className="form-floating mb-3 flex-grow-1">
                  <Form.Control
                    type="text"
                    id="state"
                    name="state"
                    placeholder="State"
                    value={formData?.state}
                    onChange={(e) => handleFieldChange("state", e.target.value)}
                  />
                  <label htmlFor="state">State</label>
                </Form.Group>
                <Form.Group className="form-floating mb-3 flex-grow-1">
                  <Form.Control
                    type="text"
                    id="city"
                    name="city"
                    placeholder="City"
                    value={formData?.city}
                    onChange={(e) => handleFieldChange("city", e.target.value)}
                  />
                  <label htmlFor="city">City</label>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    type="text"
                    id="country"
                    name="country"
                    placeholder="Country"
                    value={formData?.country}
                    onChange={(e) =>
                      handleFieldChange("country", e.target.value)
                    }
                  />
                  <label htmlFor="country">Country</label>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Link
                  to={`/contact`}
                  className="btn-link theme-text-2 text-decoration-none d-flex align-items-center mb-3"
                >
                  <EnvelopeAtFill />
                  &nbsp;
                  <span className="text-decoration-underline">
                    Request to change Email Address
                  </span>
                </Link>
              </Col>
              <Col
                lg={12}
                className="d-flex justify-content-between flex-wrap gap-2"
              >
                <Link
                  to={`/my-profile`}
                  className="btn add-button prev w-auto"
                  title="Cancel edit"
                >
                  Discard
                </Link>
                <Button type="submit" className="add-button next w-auto">
                  Update
                </Button>
              </Col>
            </Form>
          </div>
        </Container>
      )}
    </>
  );
};

export default ProfileEdit;
