import axios from "axios";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import {
  Filter,
  PencilSquare,
  PlusCircle,
  Share,
  Trash,
} from "react-bootstrap-icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Helmet } from "react-helmet";
import { BiCopy } from "react-icons/bi";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import CustomLoader from "../../components/loader/loader";
import SocialShare from "../../components/share/socialShare";
import SubDomainRouter from "../../components/subDomainRouter/subDomainRouter";
import TokenDetails from "../../components/useUserDetails/useTokenDetails";
import useUserData from "../../components/useUserDetails/useUserDetails";
import * as Actions from "../../constants/baseUrl";
import COURSEIMG from "../../img/course.png";

export default function Properties() {
  const currentDomain = window.location.hostname;

  const myToken = localStorage.getItem("token");
  const userData = useUserData();
  const role = userData.role?.toLocaleLowerCase();
  const tokenDetails = TokenDetails();
  const myUserId = tokenDetails?.Email;

  const siteName = SubDomainRouter("title");
  const propertiesAlias = SubDomainRouter("propertiesAlias");
  const uppercaseTitle = propertiesAlias.toLocaleUpperCase();

  const entities = "items";
  const entityType = "property";

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [smFilterMenu, setSmFilterMenu] = useState(false);
  const [displayedCards, setDisplayedCards] = useState(8);

  const populateList = async () => {
    setLoadingFilter(true);
    try {
      const response = await axios.get(
        `${Actions.BASE_URL}${entities}/${entityType}`,
        {
          headers: {
            Authorization: "Bearer " + myToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      // Sort data  in ascending order
      const sortedData = response.data.sort((a, b) => {
        const dateDiff = moment(a?.data?.start_date).diff(
          moment(b?.data?.start_date)
        );

        // If the dates are equal, compare based on time
        if (dateDiff === 0) {
          return moment(a?.data?.start_time, "HH:mm:ss").diff(
            moment(b?.data?.start_time, "HH:mm:ss")
          );
        }

        return dateDiff;
      });
      setItems(sortedData);
      setLoading(false);
    } catch (error) {
      console.error("Error calling API:", error);
      toast.error("Something went wrong. Please Try again later.", {
        autoClose: 3000,
        hideProgressBar: true,
        draggable: true,
      });
    } finally {
      setLoading(false);
      setLoadingFilter(false);
    }
  };
  useEffect(() => {
    populateList();
  }, []);

  // Delete Demo
  const deleteItem = (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          class: "btn btn-info",
          onClick: () => {
            axios
              .delete(`${Actions.BASE_URL}${entities}/${entityType}/${id}`, {
                headers: {
                  Authorization: "Bearer " + myToken,
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              })
              .then((res) => {
                populateList();
              });
          },
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  // Define regular expressions for filtering
  const itemList = (items) => {
    const handleShowMore = () => {
      setDisplayedCards((prevDisplayedCards) =>
        prevDisplayedCards + 4 <= items?.length
          ? prevDisplayedCards + 4
          : items?.length
      );
    };

    return (
      <div className="d-flex mt-5 pt-md-5 pt-3">
        <Container fluid="lg" className="px-lg-4">
          <Row className="my-4 justify-content-md-start justify-content-center">
            <Col className="mb-3" lg={10} sm={8}>
              <h3
                id="tabelLabel"
                className="pageSubHeading mb-0 text-sm-start text-center text-capitalize"
              >
                Checkout these {propertiesAlias ? propertiesAlias : "property"}
              </h3>
            </Col>
            {role === "admin" ||
              role === "siteadmin" ||
              role === "superadmin" ||
              role === "trainer" ||
              role === "training-coordinator" ? (
              <Col
                lg={2}
                sm={4}
                className="d-flex mb-3 align-items-center justify-content-sm-end justify-content-center"
              >
                <Link
                  to={`/property/add`}
                  type="button"
                  className="w-md-75 w-auto px-md-3 px-5 btn add-button d-flex align-items-center justify-content-center"
                >
                  <PlusCircle /> &nbsp; Add
                </Link>
              </Col>
            ) : null}
            <Col
              md={12}
              className="d-flex align-items-sm-center justify-content-between align-items-start"
            >
              <Button
                variant="link"
                className="filterToggle ms-auto p-0 text-right ms-auto d-lg-none me-lg-0 me-2 d-flex align-items-center justify-content-center btn bg-transparent mb-2"
                onClick={() => setSmFilterMenu(!smFilterMenu)}
              >
                <Filter />
              </Button>
            </Col>

            {loadingFilter ? (
              <CustomLoader />
            ) : (
              <>
                {items?.length > 0 ? (
                  items?.slice(0, displayedCards)?.map((item) => (
                    <Col
                      xxl={3}
                      xl={4}
                      md={6}
                      sm={10}
                      className="mt-2 mb-3"
                      key={item?.id}
                    >
                      <Card className="demoCard">
                        <div className="itemImg">
                          <Link
                            to={`/property/items/details/${item.id}`}
                            className="linkImg"
                          >
                            <img
                              src={
                                item?.data?.listing_image_url ||
                                item?.data?.hero_image_url ||
                                COURSEIMG
                              }
                              className="img-fluid"
                              alt="Load Error"
                              loading="lazy"
                            />
                          </Link>
                        </div>
                        <Card.Body className="itemInfo px-2 pb-3 d-flex flex-column justify-content-between py-1">
                          <div className="card-details text-center">
                            <h3 className="itemName text-left mb-0">
                              <Link
                                to={`/property/items/details/${item.id}`}
                                className="detailLink text-left"
                              >
                                {item?.data?.title}
                              </Link>
                            </h3>
                            <div className="d-flex gap-2 justify-content-center my-3 align-items-center">
                              <SocialShare
                                url={`https://${currentDomain}/items/${item?.type}/details/${item?.id}/`}
                                iconsSize={30}
                                title={item?.data?.title}
                                image={item?.data?.listing_image_url}
                                description={item?.data?.description}
                              />

                              <Link
                                to={`/share/items_${entityType}/${item.id}`}
                                className="entity-share-btn d-flex align-items-center cursor-pointer"
                                title="Share"
                              >
                                <Share />
                              </Link>
                            </div>
                            <hr className="mt-2 mb-1" />
                            <Card.Text className="card-text mb-0 pb-0 text-left text-overflow-desc p-0">
                              {item?.data?.description}
                            </Card.Text>
                          </div>
                          <div className="itemEdit d-flex flex-column flex-wrap justify-content-center mt-3 gap-2">
                            <div className="d-flex flex-wrap justify-content-center gap-2">
                              <Link
                                to={`/property/items/details/${item.id}`}
                                className="actionBtn btn action d-flex align-items-center justify-content-center text-center w-100 flex-grow-1 cursor-pointer"
                              >
                                View More
                              </Link>
                            </div>
                            <div className="d-flex flex-wrap justify-content-center gap-2">
                              {role === "admin" ||
                                role === "siteadmin" ||
                                role === "superadmin" ||
                                (item.userId &&
                                  item.userId?.toLocaleLowerCase() ===
                                  myUserId) ||
                                (item?.data?.userId &&
                                  item?.data?.userId?.toLocaleLowerCase() ===
                                  myUserId) ? (
                                <>
                                  <Link
                                    to={`/property/items/edit/${item.id}`}
                                    className="actionBtn btn d-flex align-items-center justify-content-center text-center action cursor-pointer flex-grow-1"
                                  >
                                    <span className="d-sm-block d-none">
                                      Edit
                                    </span>
                                    <PencilSquare className="d-sm-none d-block" />
                                  </Link>
                                  <Link
                                    to={`/property/items/clone/${item.id}`}
                                    className="actionBtn btn d-flex align-items-center justify-content-center text-center action cursor-pointer flex-grow-1"
                                  >
                                    <span className="d-sm-block d-none">
                                      Clone
                                    </span>
                                    <BiCopy className="d-sm-none d-block" />
                                  </Link>
                                  <Link
                                    to="#"
                                    className="actionDltBtn btn d-flex align-items-center justify-content-center text-center action cursor-pointer flex-grow-1"
                                    onClick={() => deleteItem(item.id)}
                                  >
                                    <span className="d-sm-block d-none">
                                      Delete
                                    </span>
                                    <Trash className="d-sm-none d-block" />
                                  </Link>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))
                ) : (
                  <p>No data found</p>
                )}
              </>
            )}
          </Row>
          {!loading && !loadingFilter && displayedCards < items?.length ? (
            <div className="text-center mb-5">
              <Button
                onClick={handleShowMore}
                className="btn actionBtnMore action cursor-pointer w-auto add-button"
              >
                Show More
              </Button>
            </div>
          ) : null}
        </Container>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>
          {uppercaseTitle} | {siteName ? siteName : window.location.host}
        </title>
      </Helmet>

      {loading ? <CustomLoader /> : itemList(items)}
    </>
  );
}
